



































































































































import Vue from "vue";
import api from "@/api";
import UserModel from "@/models/UserModel";
import Search from "@/components/Search.vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import { Component } from "vue-property-decorator";
@Component({
  components: {
    Search,
    SubHeader,
  },
})
export default class Profile extends Vue {
  mounted() {
    this.loadProfile();
  }
  titlePage = "Edit Profile";
  displayProfile = true;
  displayUpdateProfile = false;
  isActive = false;

  userDetail: UserModel = new UserModel();

  loadProfile() {
    return new Promise((resolve, reject) => {
      api.user
        .getUserDetail()
        .then((response) => {
          if (response.data.code === 200) {
            this.userDetail = Object.assign({}, response.data.data);
            this.cancelEdit();
            resolve(response);
          } else {
            reject(new Error("Data User tidak di temukan"));
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  }
  // cekSubject() {
  //   if (this.subject !== "") {
  //     this.isActive = true;
  //   } else if (this.subject === "") {
  //     this.isActive = false;
  //   }
  // }
  cancelEdit() {
    this.displayProfile = true;
    this.displayUpdateProfile = false;
  }
  editProfile() {
    this.displayProfile = false;
    this.displayUpdateProfile = true;
  }
  updateProfile() {
    return new Promise((resolve, reject) => {
      if (
        this.userDetail.firstname &&
        this.userDetail.lastname &&
        this.userDetail.phone
      ) {
        api.user
          .updateUser(this.userDetail)
          .then((response) => {
            if (response.data.code === 200) {
              this.loadProfile();
              resolve(response);
            } else {
              this.editProfile();
              reject(new Error("Update profile tidak berhasil"));
            }
          })
          .catch((err) => {
            reject(new Error(err));
          });
      }
    });
  }
}
